<template>
  <div>
    <ejs-dialog
      ref="groupSalesProductDetailListPopup"
      header="단체 매출 세부 내역"
      :allowDragging="true"
      :showCloseIcon="true"
      width="600"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onPopupClosed"
    >
      <div class="window groupSalesProductDetailList">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">
                      매출일자 : {{ popupData.bsnDate }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      단체명 : {{ popupData.grpName }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">단체 매출 세부 내역 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(groupSalesProductDetailList.length) }}건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositUseHistoryGrid"
                        :allowResizing="true"
                        :dataSource="groupSalesProductDetailList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="gridColumns"
                        :provides="grid"
                        :allowGrouping="false"
                        :allowSorting="false"
                        :allowFiltering="false"
                        :aggregates="gridAggregates"
                        @queryCellInfo="gridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.groupSalesProductDetailList {min-width: calc(100% - 2px);}
body .window.groupSalesProductDetailList .windowContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getByGroupInfoProductDetailList } from "@/api/salesManagement";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
import {numberWithCommas} from "@/utils/number";
import { sortBy as _sortBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "groupSalesProductDetailListPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  data: function () {
    return {
      popupData: {
        bsnDate: null,
        storeCode: null,
        grpNo: null,
        grpName: null
      },
      grid: [Aggregate, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      gridColumns: [
        {
          field: "storeCode",
          headerText: "매장",
          width: 90,
          type: "string",
          isCommonCodeField: true,
          groupCode: "STORE_CODE"
        },
        {
          field: "productName",
          headerText: "품목명",
          width: 150,
          type: "string"
        },
        {
          field: "saleAmt",
          headerText: "단가",
          width: 80,
          textAlign: "right",
          type: "number",
          format: "N"
        },
        {
          field: "saleQty",
          headerText: "수량",
          width: 70,
          textAlign: "right",
          type: "number",
          format: "N"
        },
        {
          field: "totAmt",
          headerText: "총액",
          width: 90,
          textAlign: "right",
          type: "number",
          format: "N"
        }
      ],
      gridAggregates: [
        {
          columns: [
            {
              field: "productName",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: "합계"
            },
            {
              field: "saleQty",
              aggregationType: "TotalSum"
            },
            {
              field: "totAmt",
              aggregationType: "TotalSum"
            }
          ]
        }
      ],
      groupSalesProductDetailList: []
    };
  },
  created() {},
  computed: {},
  methods: {
    numberWithCommas,
    async showPopup(popupData) {
      this.popupData = popupData;

      await getByGroupInfoProductDetailList(popupData.bsnDate, popupData.storeCode, popupData.grpNo)
        .then(response => {
          this.groupSalesProductDetailList = _sortBy(response.value, ["productName"]);
        })
        .catch((error) => {
          console.log("getByGroupInfoProductDetailList.err.===>", error);
        });
    },
    onPopupClosed() {
      this.$emit("popupClosed");
    },
    closePopup() {
      this.$refs.groupSalesProductDetailListPopup.hide();
    },
    gridQueryCellInfo() {}
  }
};
</script>